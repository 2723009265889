<template>
  <section class="audi-collection">
    <div class="content">
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
        dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      </p>
      <header class="header">
        <router-link to="/portal/cms/add-asset" class="btn btn-outline-primary btn-submit-asset">Add Asset</router-link>
        <FilterButton class="btn-filter" @button-clicked="openFilters" />
        <BaseDropdown
          :btnPreText="'Sort by: '"
          :btnText="sortBy"
          :dropdownOptions="sortByOptions"
          @selection-clicked="updateSortBy($event)"
        />
        <BaseDropdown
          :btnText="displayLimit"
          :dropdownOptions="displayLimitOptions"
          @selection-clicked="updateDisplayLimit($event)"
        />
      </header>
      <div class="asset-grid-container">
        <CmsMasonryLayout v-if="assets.length" ref="assetGrid" :assets="assets" />
        <p v-else-if="!isLoading">No assets to display with the currently selected filters</p>
      </div>

      <AssetPagination v-if="assets.length !== 0" :itemCount="totalAssetCount" :perPage="displayLimitValue" />
    </div>
    <BaseModal ref="filtersModal" :title="'Filters'" :modalType="'filter'">
      <template v-slot:modal-content>
        <FilterModal isCms @apply-filters="applyFilters" />
      </template>
    </BaseModal>
  </section>
</template>

<script>
import FilterButton from '@/components/FilterButton.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import BaseModal from '@/components/BaseModal.vue';
import FilterModal from '@/components/FilterModal.vue';
import AssetPagination from '@/components/AssetPagination.vue';
import CmsMasonryLayout from '@/views/CmsMasonryLayout.vue';
import ApiService from '@/services/api.service';
import { mapGetters } from 'vuex';

export default {
  name: 'cms-audi-collection',
  components: {
    FilterButton,
    BaseDropdown,
    BaseModal,
    FilterModal,
    AssetPagination,
    CmsMasonryLayout,
  },
  created() {
    this.page = Number(this.$route.params.page) || 1;
    this.categoryId = this.$route.meta.categoryId;
    this.getAdminAssetsByCategory();
  },
  watch: {
    '$route.params.page': {
      handler: function watchPage(newPage) {
        if (newPage) {
          this.page = newPage;
          this.getAdminAssetsByCategory();
        }
      },
    },
  },
  data() {
    return {
      isLoading: false,
      assets: [],
      categoryId: null,
      page: 1,
      sortByValue: 'asc',
      sortBy: 'Recently Added',
      filtersByType: {},
      sortByOptions: [
        {
          value: 'asc',
          name: 'Recently Added',
        },
        {
          value: 'desc',
          name: 'Least Recently Added',
        },
      ],
      displayLimit: 'Show 12',
      displayLimitValue: 12,
      displayLimitOptions: [
        {
          value: 12,
          name: 'Show 12',
        },
        {
          value: 24,
          name: 'Show 24',
        },
        {
          value: 36,
          name: 'Show 36',
        },
        {
          value: 48,
          name: 'Show 48',
        },
      ],
      totalAssetCount: 0,
    };
  },
  computed: {
    ...mapGetters(['filters']),
  },
  methods: {
    getAdminAssetsByCategory() {
      this.isLoading = true;
      ApiService.post('/admin/assets/list', {
        categoryId: this.categoryId,
        limit: this.displayLimitValue,
        page: this.page - 1,
        order: this.sortByValue,
        assetType: this.filtersByType['Asset Type'],
        modelYear: this.filtersByType['Model Year'],
        model: this.filtersByType.Model,
        jobRole: this.filtersByType['Job Role'],
      })
        .then(({ data }) => {
          this.assets = data?.data?.assets;
          this.totalAssetCount = data?.data?.totalAssetCount;
        })
        .finally(() => {
          if (this.assets.length === 0 && this.page !== 1) {
            this.$router.push({ name: this.$router.name, params: { page: 1 } });
          }
          this.isLoading = false;
        });
    },
    openFilters() {
      this.$refs.filtersModal.openModal();
    },
    applyFilters() {
      this.$refs.filtersModal.hideModal();
      this.calculateActiveFilters();
      this.getAdminAssetsByCategory();
    },
    calculateActiveFilters() {
      this.filtersByType = {};
      this.filters.forEach((filter) => {
        filter.parameters.forEach((parameter) => {
          if (parameter.isActive) {
            if (this.filtersByType[filter.title]) {
              this.filtersByType[filter.title]?.push(filter.isCheckbox ? parameter.id : parameter.name);
            } else {
              this.filtersByType[filter.title] = [filter.isCheckbox ? parameter.id : parameter.name];
            }
          }
        });
      });
    },
    updateSortBy(event) {
      this.sortBy = event.name;
      this.sortByValue = event.value;
      this.page = 1;
      this.getAdminAssetsByCategory();
    },
    updateDisplayLimit(event) {
      this.displayLimit = event.name;
      this.displayLimitValue = event.value;
      this.getAdminAssetsByCategory();
    },
  },
};
</script>

<style lang="scss" scoped>
header.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  h1 {
    @include bp-lg-laptop {
      width: 100%;
    }
  }
  .btn-submit-asset {
    @include bp-lg-laptop {
      order: 4;
      margin-left: auto;
    }
  }
  .btn-filter {
    @include bp-lg-laptop {
      max-width: 165px;
      margin-right: 1.875rem; // 30px
    }
  }
  .dropdown {
    @include bp-lg-laptop {
      margin-right: 1.875rem; // 30px
    }
  }
}
.asset-grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
